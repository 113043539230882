<template>
  <div class="detail">
    <div class="header">
      <div class="target-name">{{this.targetInfo.name}}</div>
      <el-dropdown>
        <img class="btn-more" src="@/assets/images/icon-more.png"/>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div @click="toppingConversation">{{isTop ? '取消置顶' : '置顶'}}</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="deleteConversation">删除</div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div @click="toUserDetail">主页</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="msg-list" ref="scrollListRef" @scroll="handleListScroll">
      <div style="font-size: 12px; color: #999; text-align: center; padding-top: 10px;"
      v-loading="loadMore.isLoading" 
      element-loading-spinner="el-icon-loading" 
      element-loading-background="rgba(0, 0, 0, 0)">{{loadMore.hasMore ? '':'没有更多了~'}}</div>
      <div class="msg-warp" v-for="(item,index) in msgList" :key="index">
        <div class="msg-date" v-if="getMsgDate(item.serverTime, index)">{{getMsgDate(item.serverTime, index)}}</div>
        <div class="msg" v-if="item.direction == 1">
          <LoadImage class="avatar" v-if="isShowLeftAvatar(item.content.type)" :src="targetInfo.avatar" :default="require('@/assets/images/default-avatar.png')"/>
          <!-- 文本消息 -->
          <div class="content" v-if="item.content.type == 1 && item.content.content.length > 0" v-html="item.content.content"></div>
          <!-- 图片 -->
          <LoadImage class="msg-img" v-if="item.content.type == 3" :src="item.content.imgUrl" :preview="true" ></LoadImage>
          <!-- 提示消息 -->
          <div class="msg-tips" v-if="item.content.type == 90">
            <div class="tips-txt">{{item.content.tip}}</div>
          </div>
          <!-- 撤回消息 -->
          <div class="msg-tips" v-if="item.content.type == 80">
            <div class="tips-txt">对方撤回了一条消息</div>
          </div>
          <!-- 任务卡片 -->
          <TaskCard v-if="item.content.type == 1001" :info="item.content.content"/>
          <!-- 定位消息 -->
          <LocationMsg v-if="item.content.type == 4" :info="item"></LocationMsg>
        </div>
        <div class="msg msg-right" v-if="item.direction == 0">
          <!-- 文本消息 -->
          <div class="content" v-if="item.content.type == 1 && item.content.content.length > 0" v-html="item.content.content"></div>
          <!-- 图片 -->
          <LoadImage class="msg-img" v-if="item.content.type == 3" :src="item.content.imgUrl" :preview="true" ></LoadImage>
          <!-- 任务卡片 -->
          <TaskCard v-if="item.content.type == 1001" :info="item.content.content"/>
          <!-- 定位消息 -->
          <LocationMsg v-if="item.content.type == 4" :info="item"></LocationMsg>
          <LoadImage class="avatar" :src="userInfo?.headImg" :default="require('@/assets/images/default-avatar.png')"/>
        </div>
      </div>
      
    </div>

    <!-- 底部操作区 -->
    <div class="editor">
      <div class="flex between-center">
        <div class="flex items-center">
          <el-popover
            popper-class="custom-popover"
            placement="top"
            trigger="click">
            <img slot="reference" src="@/assets/images/icon-emote.png"/>
            <Emote @choose="chooseEasysayOrEmote"/>
          </el-popover>
          <UploadImage :auto-upload="false" :showUploadImg="false" @success="sendImage">
            <img class="ml8" src="@/assets/images/icon-picture.png"/>
          </UploadImage>
          
        </div>
        <Easysay @choose="chooseEasysayOrEmote"/>
      </div>
      <textarea v-model="inputValue" placeholder="请输入消息，按Enter 或 点击发送按钮发送" @keydown="handleKeyDown"></textarea>
      <div class="flex justify-end">
        <div class="btn-send btn-fill-theme" @click="sendMsg">发送消息</div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadImage from '@/components/LoadImage'
import UploadImage from '@/components/UploadImage'
import Emote from '../components/Emote.vue'
import Easysay from '../components/Easysay.vue'
import TaskCard from './components/TaskCard.vue'
import LocationMsg from './components/LocationMsg.vue'

import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
import { convertEmote } from '@/utils/convertEmote.js'
import { gt, gte, eq, longAdd, stringValue } from '@/utils/longUtil.js'
import { formatMsgDate } from '@/utils/util.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
import { createConversation, sendMessageApi } from '@/utils/jim/im.js'
import EventType from "@/wfc/client/wfcEvent";
import { mapState } from 'vuex'
import { searchUserApi } from '@/api/admin.js'
export default {
  name: 'MessageDetail',
  components: {
    LoadImage,
    UploadImage,
    Emote,
    Easysay,
    TaskCard,
    LocationMsg
  },
  data() {
    return {
      inputValue: '',
      conversation: null,
      targetInfo: {
        targetId:'',
        avatar: '',
        name: '',
        conversation: null
      },
      lastMessageUid: null,
      msgList: [],
      loadMore: {
        isLoading: false,
        hasMore: true
      },
      connectStatus: 0,
      isTop: false
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
    this.connectStatus = this.$im.getConnectionStatus()
    let chatParams = localStorage.getItem('CHATROOM_PARAMS')
    console.log('-----------chatParams-------------', chatParams)
    if(chatParams) {
      chatParams = JSON.parse(chatParams)
      this.targetInfo.targetId = chatParams.targetId || chatParams.conversation.target || ''
      this.targetInfo.conversation = chatParams.conversation || null
      this.lastMessageUid = chatParams.lastMessageUid || 0
      this.isTop = chatParams.isTop || false
      this.getTargetInfo()
      this.getMsgList()
      // 处理未读消息状态
      this.$im.clearConversationUnreadStatus(this.targetInfo.conversation)
      this.$store.dispatch('im/UPDATE_UNREAD')
    } else {
      this.$router.replace('/message')
    }
    
    this.$im.eventEmitter.on(EventType.ConnectionStatusChanged, this.onConnectionStatusChange)
    this.$im.eventEmitter.on(EventType.ReceiveMessage, this.receiveMsg)
    window.addEventListener('beforeunload', () => {
      // 页面刷新、关闭需清除该缓存
      localStorage.removeItem('CHATROOM_PARAMS')
    })
  },
  beforeDestroy() {
    this.$im.eventEmitter.off(EventType.ConnectionStatusChanged, this.onConnectionStatusChange)
    this.$im.eventEmitter.off(EventType.ReceiveMessage, this.receiveMsg)
    // 由于receiveMsg接收到消息时，本地缓存列表还未更新，立即调用无法生效（jim-client的问题），在此调用可生效
    if(this.targetInfo.conversation) this.$im.clearConversationUnreadStatus(this.targetInfo.conversation)
  },
  methods: {
    handleKeyDown(e) {
      if(e.keyCode == 13) {
        if(!e.shiftKey) {
          e.preventDefault()
          this.sendMsg()
        }
      }
    },
    chooseEasysayOrEmote(val) {
      this.inputValue += val
    },
    getMsgList() {
      if(!this.targetInfo.targetId) return
      this.$im.getMessages(this.targetInfo.conversation, (code, messages) => {
        if (messages) {
          console.log('详情消息列表',messages)
          this.msgList = messages.map(item => this.convertMsg(item))
          this.scrollToBottom()
        }
      }, longAdd(this.lastMessageUid, 1))
    },
    // 获取聊天对象信息 头像/名字
    getTargetInfo() {
      this.$im.getUserInfo(this.targetInfo.targetId, res => {
        console.log('对方信息', res)
        if(res?.user?.portrait) {
          ossKeyToUrl({ key: res.user.portrait }).then(ossRes => {
            this.targetInfo.avatar = ossRes.url
          }).catch(err => {})
        }
        if(res?.user?.display_name) this.targetInfo.name = res.user.display_name || ''
      })
    },
    convertMsg(item) {
      // 文本 表情处理
      if(item.content.type == 1) {
        item.content.content = convertEmote(item.content?.content || item.messageContent?.content || item.content?.searchable_content  || '')
      }
      // 提示消息
      if(item.content.type == 90) {
        item.content.tip = item.content?.tip || item.messageContent?.content || item.content?.searchable_content || ''
      }
      // 图片处理
      if(item.content.type == 3) {
        const path = item.content.remoteMediaUrl || item.messageContent.remotePath
        item.content.imgUrl = ''
        if(path.startsWith('oss')) {
          console.log('消息图片', path)
          ossKeyToUrl({ key: path }).then(res => {
            item.content.imgUrl = res.url
            this.$set(item, 'content', Object.assign({}, item.content))
            console.log('-----消息图片转换----', item)
          }).catch(err => {})
        } else {
          item.content.imgUrl = path
        }
      }
      // 任务卡片
      if(item.content.type == 1001) {
        // 可能直接返回Object, 不需要JSON.parse
        if(typeof item.content.content == 'string') {
          item.content.content = JSON.parse(item.content.content)
        }
      }
      return item
    },
    getMsgDate(time, index) {
      // 时间处理
      if(index == 0) {
        return formatMsgDate(time)
      } else {
        const lastTime = this.msgList[index-1].serverTime
        return time - lastTime > 5*60*1000 ? formatMsgDate(time) : ''
      }
    },
    scrollToBottom() {
      if(!this.$refs.scrollListRef) return
      const timer = setTimeout(() => {
        this.$refs.scrollListRef.scrollTop = this.$refs.scrollListRef.scrollHeight
        clearTimeout(timer)
      }, 200)
    },
    // 发送消息
    sendMsg() {
      if(!this.inputValue.trim()) return 
      sendMessageApi({
        'type': 'text',  
        'conversation': this.targetInfo.conversation, 
        'value': this.inputValue.trim()
      }).then(msg => {
        this.msgList.push(this.convertMsg(msg))
        this.scrollToBottom()
        this.inputValue = ''
        this.$eventBus.$emit('UpdateChatList')
      }).catch(err => {
        this.$tips({message: '发送失败', type: 'error'})
      })
    },
    // 发送图片
    async sendImage(e) {
      console.log('选择图片',e)
      try {
        const { uploadImageAsync } = await uploadImageUtil({keyType: 'chat_'})
        const res = await uploadImageAsync(e.file)
        const msg = await sendMessageApi({
          'type': 'image', 
          'conversation': this.targetInfo.conversation, 
          'value': res.ossUrl
        })
        this.msgList.push(this.convertMsg(msg))
        this.scrollToBottom()
        this.$eventBus.$emit('UpdateChatList')
      } catch (err) {
        this.$tips({message: '发送失败', type: 'error'})
      } 
      
    },
    receiveMsg(e) {
      console.log('详情接收消息', e)
      e.forEach(item => {
        const converInfo = this.targetInfo.conversation
        if(item.content.type == 80) {
          const shouldAdd =
          converInfo && 
          converInfo.target == item.conversation.target && 
          converInfo.line == item.conversation.line &&
          converInfo.type == item.conversation.type
          if(shouldAdd) {
            const index = this.msgList.findIndex(item1 => eq(item1.messageUid, item.messageUid))
            if(index != -1) {
              this.msgList.splice(index, 1, this.convertMsg(item))
            }
            this.scrollToBottom()
            this.$im.clearConversationUnreadStatus(this.targetInfo.conversation)
          }
        } else {
          const shouldAdd =
          converInfo && 
          converInfo.target == item.conversation.target && 
          converInfo.line == item.conversation.line &&
          converInfo.type == item.conversation.type &&
          (!this.lastMessageUid || gt(item.messageUid, this.lastMessageUid)) &&
          item.content.type != 91 // 消息正在输入
          console.log('------shouldAdd------', shouldAdd, converInfo, gt(item.messageUid, this.lastMessageUid))
          console.log('------muid-----------', stringValue(item.messageUid), stringValue(this.lastMessageUid))
          if(shouldAdd) {
            this.msgList.push(this.convertMsg(item))
            console.log('----接收消息后----',this.msgList)
            this.lastMessageUid = item.messageUid
            this.scrollToBottom()
            // 由于receiveMsg接收到消息时，本地缓存列表还未更新，立即调用无法生效（jim-client的问题）
            this.$im.clearConversationUnreadStatus(this.targetInfo.conversation)
          }
        }
        
      })
    },
    onConnectionStatusChange(status) {
      console.log('连接状态', status)
      if(status == 1 && this.connectStatus != 1) {
        this.getMsgList()
      }
      this.connectStatus = status
    },
    handleListScroll(e) {
      // 加载历史消息
      if(e.target.scrollTop == 0) {
        if(!this.loadMore.hasMore) return
        this.loadMore.isLoading = true
        let msgUid = this.msgList[0].messageUid
        const oldHeight = e.target.scrollHeight
        this.$im.getMessages(this.targetInfo.conversation, (code, messages) => {
          console.log('历史消息', messages)
          this.loadMore.isLoading = false
          if (messages && messages.length > 0) {
            const list = messages.map(item => this.convertMsg(item))
            this.msgList.unshift(...list)
            this.$nextTick(() => {
              const newHeight = e.target.scrollHeight
              this.$refs.scrollListRef.scrollTo(0, newHeight - oldHeight)
            })
          } else {
            this.loadMore.hasMore = false
          }
        }, msgUid)
      }
    },
    // 删除会话
    deleteConversation() {
      this.$confirm('确认删除该聊天吗').then(action => {
        if(action == 'confirm') {
          this.$im.removeConversation(this.targetInfo.conversation)
          this.$eventBus.$emit('UpdateChatList', {isRemoveChat: true})
          this.$router.replace('/message')
        }
      }).catch(err => {})
    },
    toppingConversation() {
      // 置顶
      this.isTop = !this.isTop
      this.$im.setConversationTop(this.targetInfo.conversation, this.isTop)
      this.$eventBus.$emit('UpdateChatList')
    },
    isShowLeftAvatar(type) {
      if(type == 80 || type == 90 || type == 2006 || type == 2004) return false
      return true
    },
    toUserDetail() {
      searchUserApi({
        searchWord: this.targetInfo.targetId
      }).then(res => {
        console.log('搜索用户', res)
        const list = res.data.searchUsers || []
        if(list.length == 0) return this.$tips({message:'用户不存在', type: 'warning'})
        const uInfo = list[0]
        if(uInfo.userType == 1) {
          this.$router.push({
            path: '/detail/user',
            query: {
              uid: this.targetInfo.targetId
            }
          })
        }
        if(uInfo.userType == 2) {
          this.$router.push({
            path: '/detail/merchant',
            query: {
              mid: this.targetInfo.targetId
            }
          })
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 500px;
::-webkit-scrollbar {
	width: 5px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7FBFC;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 24px;
  background-color: #fff;
  .target-name {
    font-size: 16px;
    font-weight: bold;
  }
  .btn-more {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.msg-list {
  flex: 1;
  padding: 0 24px 30px;
  overflow-y: auto;
  .msg {
    display: flex;
    margin-top: 16px;
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      overflow: hidden;
      margin-right: 6px;
    }
    .content {
      padding: 12px 16px;
      border-radius: 0 8px 8px 8px;
      background-color: #fff;
      max-width: $contentWidth;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }

  .msg-right {
		display: flex;
		justify-content: flex-end;
		.avatar {
			margin-left: 6px;
		}
		.content {
      border-radius: 8px 0 8px 8px;
			background-color: var(--theme-color);
			color: #fff;
		}
	}

  // 时间
  .msg-date {
		font-size: 12px;
		color: #50545F;
		padding-top: 16px;
		text-align: center;
	}

  // 提示消息
	.msg-tips {
		flex: 1;
		margin-top: 16px;
		font-size: 12px;
		color: #999999;
		text-align: center;
		.tips-txt {
			max-width: 250px;
			margin: 0 auto;
		}
	}

  // 图片消息
  .msg-img {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    overflow: hidden;
  }
}

// 底部操作区
.editor {
  display: flex;
  flex-direction: column;
  height: 194px;
  padding: 24px;
  background-color: #fff;

  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .emote-wrap{
    display: flex;
  }
  .btn-send {
    height: 38px;
    line-height: 38px;
    padding: 0 18px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  textarea {
    resize: none;
    flex: 1;
    margin: 8px 0;
    &::-webkit-input-placeholder{   /* 使用webkit内核的浏览器 */
      color: #BABFBF;
    }
    &:-moz-placeholder{    /* Firefox版本4-18 */
      color: #BABFBF;
    }              
    &::-moz-placeholder{    /* Firefox版本19+ */
      color: #BABFBF;
    }              
    &:-ms-input-placeholder{   /* IE浏览器 */
      color: #BABFBF;
    } 
  }
}

</style>