<template>
  <div class="emotes">
    <img src="http://global.xuniuwang.com/static/emoji/weixiao.png" alt="" @click="chooseEmoji('微笑')">
		<img src="http://global.xuniuwang.com/static/emoji/nanshou.png" alt="" @click="chooseEmoji('难受')">
		<img src="http://global.xuniuwang.com/static/emoji/xiai.png" alt="" @click="chooseEmoji('喜爱')">
		<img src="http://global.xuniuwang.com/static/emoji/kaixin.png" alt="" @click="chooseEmoji('开心')">
		<img src="http://global.xuniuwang.com/static/emoji/liezuixiao.png" alt="" @click="chooseEmoji('咧嘴笑')">
		<img src="http://global.xuniuwang.com/static/emoji/shengqi.png" alt="" @click="chooseEmoji('生气')">
		<img src="http://global.xuniuwang.com/static/emoji/ganga.png" alt="" @click="chooseEmoji('尴尬')">
		<img src="http://global.xuniuwang.com/static/emoji/daku.png" alt="" @click="chooseEmoji('大哭')">
		<img src="http://global.xuniuwang.com/static/emoji/haixiu.png" alt="" @click="chooseEmoji('害羞')">
		<img src="http://global.xuniuwang.com/static/emoji/jingya.png" alt="" @click="chooseEmoji('惊讶')">
		<img src="http://global.xuniuwang.com/static/emoji/zhuangku.png" alt="" @click="chooseEmoji('装酷')">
		<img src="http://global.xuniuwang.com/static/emoji/bizui.png" alt="" @click="chooseEmoji('闭嘴')">
		<img src="http://global.xuniuwang.com/static/emoji/kuxiao.png" alt="" @click="chooseEmoji('哭笑')">
		<img src="http://global.xuniuwang.com/static/emoji/kuqi.png" alt="" @click="chooseEmoji('哭泣')">
		<img src="http://global.xuniuwang.com/static/emoji/dayan.png" alt="" @click="chooseEmoji('大眼盯着')">
		<img src="http://global.xuniuwang.com/static/emoji/gulingjingguai.png" alt="" @click="chooseEmoji('古灵精怪')">
		<img src="http://global.xuniuwang.com/static/emoji/gaoguai.png" alt="" @click="chooseEmoji('搞怪')">
		<img src="http://global.xuniuwang.com/static/emoji/yihuo.png" alt="" @click="chooseEmoji('疑问')">
		<img src="http://global.xuniuwang.com/static/emoji/yun.png" alt="" @click="chooseEmoji('晕')">
		<img src="http://global.xuniuwang.com/static/emoji/kongbu.png" alt="" @click="chooseEmoji('恐怖')">
		<img src="http://global.xuniuwang.com/static/emoji/kelian.png" alt="" @click="chooseEmoji('可怜')">
		<img src="http://global.xuniuwang.com/static/emoji/wuyu.png" alt="" @click="chooseEmoji('无语')">
		<img src="http://global.xuniuwang.com/static/emoji/koubi.png" alt="" @click="chooseEmoji('抠鼻')">
		<img src="http://global.xuniuwang.com/static/emoji/fengdou.png" alt="" @click="chooseEmoji('奋斗')">
		<img src="http://global.xuniuwang.com/static/emoji/touxiao.png" alt="" @click="chooseEmoji('偷笑')">
		<img src="http://global.xuniuwang.com/static/emoji/shihua.png" alt="" @click="chooseEmoji('石化')">
		<img src="http://global.xuniuwang.com/static/emoji/shuijiao.png" alt="" @click="chooseEmoji('睡觉')">
		<img src="http://global.xuniuwang.com/static/emoji/jianqianyankai.png" alt="" @click="chooseEmoji('见钱眼开')">
		<img src="http://global.xuniuwang.com/static/emoji/youchou.png" alt="" @click="chooseEmoji('忧愁')">
		<img src="http://global.xuniuwang.com/static/emoji/zaijian.png" alt="" @click="chooseEmoji('再见')">
		<img src="http://global.xuniuwang.com/static/emoji/buli.png" alt="" @click="chooseEmoji('不理')">
		<img src="http://global.xuniuwang.com/static/emoji/weiqu.png" alt="" @click="chooseEmoji('委屈')">
		<img src="http://global.xuniuwang.com/static/emoji/lenhan.png" alt="" @click="chooseEmoji('冷汗')">
		<img src="http://global.xuniuwang.com/static/emoji/shengbin.png" alt="" @click="chooseEmoji('生病')">
		<img src="http://global.xuniuwang.com/static/emoji/huaji.png" alt="" @click="chooseEmoji('滑稽')">
		<img src="http://global.xuniuwang.com/static/emoji/jianzha.png" alt="" @click="chooseEmoji('奸诈')">
		<img src="http://global.xuniuwang.com/static/emoji/gandong.png" alt="" @click="chooseEmoji('感动')">
		<img src="http://global.xuniuwang.com/static/emoji/aixin.png" alt="" @click="chooseEmoji('爱心')">
		<img src="http://global.xuniuwang.com/static/emoji/xinsui.png" alt="" @click="chooseEmoji('心碎')">
		<img src="http://global.xuniuwang.com/static/emoji/ok.png" alt="" @click="chooseEmoji('ok')">
		<img src="http://global.xuniuwang.com/static/emoji/dianzan.png" alt="" @click="chooseEmoji('点赞')">
		<img src="http://global.xuniuwang.com/static/emoji/diancai.png" alt="" @click="chooseEmoji('点踩')">
		<img src="http://global.xuniuwang.com/static/emoji/woshou.png" alt="" @click="chooseEmoji('握手')">
		<img src="http://global.xuniuwang.com/static/emoji/qidao.png" alt="" @click="chooseEmoji('祈祷')">
		<img src="http://global.xuniuwang.com/static/emoji/meiguihua.png" alt="" @click="chooseEmoji('玫瑰花')">
		<img src="http://global.xuniuwang.com/static/emoji/meiguihuadiaoxie.png" alt="" @click="chooseEmoji('玫瑰花凋谢')">
		<img src="http://global.xuniuwang.com/static/emoji/jiubei.png" alt="" @click="chooseEmoji('酒杯')">
  </div>
</template>

<script>
export default {
	data() {
		return {}
	},
  methods: {
    chooseEmoji(name){
      this.$emit('choose','['+name+']')
    }
  }
}
</script>

<style lang="scss" scoped>
.emotes {
  display: flex;
  flex-wrap: wrap;
  width: 480px;
	padding: 8px;
	border-radius: 8px;
	background-color: #fff;
  img {
    width: 46px;
    height: 46px;
		padding: 8px;
		cursor: pointer;
		&:hover {
			background-color: #f5f5f5;
		}
  }
}
</style>