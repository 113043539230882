export function convertEmote(text){
  
	text=text.replace(/\[微笑\]/g,`<img src="http://global.xuniuwang.com/static/emoji/weixiao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[难受\]/g,`<img src="http://global.xuniuwang.com/static/emoji/nanshou.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[喜爱\]/g,`<img src="http://global.xuniuwang.com/static/emoji/xiai.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[开心\]/g,`<img src="http://global.xuniuwang.com/static/emoji/kaixin.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[咧嘴笑\]/g,`<img src="http://global.xuniuwang.com/static/emoji/liezuixiao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[生气\]/g,`<img src="http://global.xuniuwang.com/static/emoji/shengqi.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[尴尬\]/g,`<img src="http://global.xuniuwang.com/static/emoji/ganga.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[大哭\]/g,`<img src="http://global.xuniuwang.com/static/emoji/daku.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[害羞\]/g,`<img src="http://global.xuniuwang.com/static/emoji/haixiu.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[惊讶\]/g,`<img src="http://global.xuniuwang.com/static/emoji/jingya.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[装酷\]/g,`<img src="http://global.xuniuwang.com/static/emoji/zhuangku.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[闭嘴\]/g,`<img src="http://global.xuniuwang.com/static/emoji/bizui.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[哭笑\]/g,`<img src="http://global.xuniuwang.com/static/emoji/kuxiao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[哭泣\]/g,`<img src="http://global.xuniuwang.com/static/emoji/kuqi.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[大眼盯着\]/g,`<img src="http://global.xuniuwang.com/static/emoji/dayan.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[古灵精怪\]/g,`<img src="http://global.xuniuwang.com/static/emoji/gulingjingguai.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[搞怪\]/g,`<img src="http://global.xuniuwang.com/static/emoji/gaoguai.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[疑问\]/g,`<img src="http://global.xuniuwang.com/static/emoji/yihuo.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[晕\]/g,`<img src="http://global.xuniuwang.com/static/emoji/yun.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[恐怖\]/g,`<img src="http://global.xuniuwang.com/static/emoji/kongbu.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[可怜\]/g,`<img src="http://global.xuniuwang.com/static/emoji/kelian.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[无语\]/g,`<img src="http://global.xuniuwang.com/static/emoji/wuyu.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[抠鼻\]/g,`<img src="http://global.xuniuwang.com/static/emoji/koubi.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[奋斗\]/g,`<img src="http://global.xuniuwang.com/static/emoji/fengdou.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[偷笑\]/g,`<img src="http://global.xuniuwang.com/static/emoji/touxiao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[石化\]/g,`<img src="http://global.xuniuwang.com/static/emoji/shihua.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[睡觉\]/g,`<img src="http://global.xuniuwang.com/static/emoji/shuijiao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[见钱眼开\]/g,`<img src="http://global.xuniuwang.com/static/emoji/jianqianyankai.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[忧愁\]/g,`<img src="http://global.xuniuwang.com/static/emoji/youchou.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[再见\]/g,`<img src="http://global.xuniuwang.com/static/emoji/zaijian.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[不理\]/g,`<img src="http://global.xuniuwang.com/static/emoji/buli.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[委屈\]/g,`<img src="http://global.xuniuwang.com/static/emoji/weiqu.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[冷汗\]/g,`<img src="http://global.xuniuwang.com/static/emoji/lenhan.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[生病\]/g,`<img src="http://global.xuniuwang.com/static/emoji/shengbin.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[滑稽\]/g,`<img src="http://global.xuniuwang.com/static/emoji/huaji.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[奸诈\]/g,`<img src="http://global.xuniuwang.com/static/emoji/jianzha.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[感动\]/g,`<img src="http://global.xuniuwang.com/static/emoji/gandong.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[爱心\]/g,`<img src="http://global.xuniuwang.com/static/emoji/aixin.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[心碎\]/g,`<img src="http://global.xuniuwang.com/static/emoji/xinsui.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[ok\]/g,`<img src="http://global.xuniuwang.com/static/emoji/ok.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[点赞\]/g,`<img src="http://global.xuniuwang.com/static/emoji/dianzan.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[点踩\]/g,`<img src="http://global.xuniuwang.com/static/emoji/diancai.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[握手\]/g,`<img src="http://global.xuniuwang.com/static/emoji/woshou.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[祈祷]/g,`<img src="http://global.xuniuwang.com/static/emoji/qidao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[玫瑰花\]/g,`<img src="http://global.xuniuwang.com/static/emoji/meiguihua.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[玫瑰花凋谢\]/g,`<img src="http://global.xuniuwang.com/static/emoji/meiguihuadiaoxie.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[酒杯\]/g,`<img src="http://global.xuniuwang.com/static/emoji/jiubei.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	text=text.replace(/\[龇牙\]/g,`<img src="http://global.xuniuwang.com/static/emoji/liezuixiao.png" alt="" style='height:22px;width:22px;vertical-align: -5px;'>`)
	return text;
}