<template>
  <div>
    <el-popover
      popper-class="custom-popover"
      trigger="click"
      placement="top"
      @hide="handleHide">
      <div class="btn-easysay" slot="reference">常用语</div>
      <div class="easysay">
        <div class="flex between-center mb16 pr16">
          <div class="title">常用语</div>
          <img v-if="!isSetting" class="icon-setting" src="@/assets/images/icon-setting.png" @click="isSetting = true">
          <span v-else class="pointer" @click="isSetting = false">取消编辑</span>
        </div>
        <div class="mr16">
          <el-input size="mini" v-model="searchWord" clearable @clear="handleClear">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
        </div>
        <div class="easysay-list" v-if="easysayList?.length > 0">
          <div class="easysay-item" v-for="(item, index) in easysayList" :key="index" @click="$emit('choose', item)">
            <div class="txt">{{item}}</div>
            <!-- <div v-if="!isSetting" class="theme-text pointer">发送</div> -->
            <div v-if="isSetting" class="btns">
              <img src="@/assets/images/icon-edit-line1.png" @click.stop="handleModify(index)">
              <img v-if="index == 0"  src="@/assets/images/up-disable.png">
              <img v-else src="@/assets/images/icon-moveup.png" @click.stop="handleMoveUp(index)">
              <img src="@/assets/images/icon-delete.png" @click.stop="handleDelete(index)">
            </div>
          </div>
        </div>
        <el-empty v-else class="mt24 mb24" description="暂无数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
        <div class="btn-add" @click="$refs.popupRef.open()">
          <img src="@/assets/images/add-plus.png">
          <span>新增常用语</span>
        </div>
      </div>
    </el-popover>

    <Popup ref="popupRef" :title="`${isModify ? '编辑':'新增'}常用语`" width="500px" @confirm="handleConfirm" @close="resetData">
      <el-input v-model="inputValue" type="textarea" 
      placeholder="请输入常用语，不超过100字" rows="5" maxlength="100" show-word-limit></el-input>
    </Popup>
  </div>
  
</template>

<script>
import Popup from '@/components/Popup'
import { mapState } from 'vuex'
export default {
  components: {
    Popup
  },
  data() {
    return {
      isSetting: false,
      isModify: false, // 是否修改常用语
      modifyIndex: '',
      inputValue: '',
      searchWord: '',
      easysayList: []
    }
  },
  created() {
    this.easysayList = this.$store.state.easysay || []
  },
  methods: {
    resetData() {
      this.inputValue = ''
      this.modifyIndex = ''
      this.isModify = false
    },
    handleHide() {
      this.isSetting = false
    },
    handleConfirm() {
      if(!this.inputValue.trim()) return this.$tips({message: '内容不能为空', type: 'warning'})
      if(this.isModify) {
        this.easysayList.splice(this.modifyIndex, 1, this.inputValue)
        this.$tips({message: '修改成功', type: 'success'})
      } else {
        this.easysayList.unshift(this.inputValue)
        this.$tips({message: '添加成功', type: 'success'})
      }
      this.$store.commit('SET_EASYSAY', this.easysayList)
      this.$refs.popupRef.close()
    },
    handleModify(index) {
      this.isModify = true
      this.modifyIndex = index
      this.inputValue = this.easysayList[index]
      this.$refs.popupRef.open()
    },
    handleMoveUp(index) {
      if(index == 0) return
      const list = this.easysayList
      list.splice(index-1, 0, ...list.splice(index, 1))
      this.$store.commit('SET_EASYSAY',list)
    },
    handleDelete(index) {
      this.$confirm('确定删除此常用语吗？').then(action => {
        if(action == 'confirm') {
          this.easysayList.splice(index, 1)
          this.$store.commit('SET_EASYSAY',this.easysayList)
        }
      }).catch(err => {})
    },
    handleSearch() {
      if(!this.searchWord.trim()) return
      const list = this.easysayList.filter(item => item.includes(this.searchWord.trim()))
      this.easysayList = list
    },
    handleClear() {
      this.easysayList = this.$store.state.easysay
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 5px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.btn-easysay {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--theme-color);
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
}
// 快捷用语
.easysay {
  width: 300px;
  border-radius: 8px;
  padding: 16px 0 16px 16px;
  background-color: #fff;
  font-size: 14px;
  .title {
    color: var(--theme-color);
  }
  .icon-setting {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 8px;
  }
  .easysay-list {
    height: 45vh;
    overflow-y: auto;
    padding-right: 16px;
    .easysay-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54px;
      line-height: 54px;
      border-top: 1px solid #f5f5f5;
      cursor: pointer;
    }
    .txt {
      flex: 1;
      // max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    
  }
  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    font-size: 12px;
    margin-right: 24px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
}
</style>