<template>
  <!-- 任务卡片 -->
  <div class="task-card" v-if="info">
    <div>
      <div class="task-title-row">
        <span class="task-title">{{info.tt}}</span>
        <span class="price">{{info.p}}元</span>
      </div>
      <div class="task-project">{{info.tp || ''}}</div>
    </div>
    <div class="refuse" v-if="info.s && (info.at || info.rr ||  info.ap && info.ap.length > 0)">
      <div class="wrap" v-if="info.at">
        <span class="left">处理截止：</span>
        <span class="right">{{info.at}}</span>
      </div>
      <div class="wrap" v-if="info.rr">
        <span class="left">驳回理由：</span>
        <span class="right">{{info.rr}}</span>
      </div>
      <div class="wrap" v-if="info.ap && info.ap.length > 0">
        <span class="left">驳回图片：</span>
        <div class="task-imgs right">
          <!-- <div class="img" v-for="(url, uri) in info.ap" :key="uri" @click.stop="showPic(url)">
            <u--image :src="url" width="60rpx" height="60rpx" radius="8">
              <template v-slot:error>
                <image style="width: 30rpx; height: 30rpx;" src="/pageMessage/static/img/img-error.png"></image>
              </template>
            </u--image>
          </div> -->
          <LoadImage class="img" v-for="(url, index) in info.ap" :key="index" preview></LoadImage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadImage from '@/components/LoadImage'
export default {
  components: {
    LoadImage
  },
  data() {
    return {

    }
  },
  props: {
    info: {
      type: Object,
      default: () => null
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
// 任务卡片
.task-card {
  padding: 12px 12px 4px;
  box-sizing: border-box;
  font-size: 14px;
  background: #fff;
  border-radius: 10px;
  width: 240px;
  .task-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  .task-title {
    flex: 1;
  }
  .price {
    color: #FF5C19;
    margin-left: 20rpx;
  }
  .task-project {
    // border-bottom: 1rpx solid rgba(0,0,0,0.1);
    // margin-bottom: 16rpx;
    padding-bottom: 8px;
  }
  .refuse {
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 8px;
  }
  .wrap {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 8px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .left {
      color: #666666;
    }
    .right {
      flex: 1;
    }
  }
  .task-imgs {
    display: flex;
    .img {
      width: 30px;
      height: 30px;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 4px !important;
    }
  }
}
</style>